import React from "react";

import HeaderPrimary from "../headerPrimary/headerPrimary";
import ContactSection from "../contactSection/contactSection";
import classes from "./contactPage.module.css";
import heroImg from "../../assets/images/heroImg.png";

const contactPage = () => {
	return (
		<>
			<div className="wrapper">
				<HeaderPrimary></HeaderPrimary>

				<main className="main">
					<section className={classes.contact}>
						<div className={`${classes.contactContainer} container`}>
							<div className={classes.contact__txt}>
								<h1 className={classes.contactTitle}>Контакты</h1>
								<p className={classes.contactDescr}>
									Номер телефона: <a href="tel:+7 (953) 265-17-91">+7 (953) 265-17-91</a>
									<br />
									Почта: <a href="mailto:Nowik.zahar@yandex.ru">Nowik.zahar@yandex.ru</a>
									<br />
									<address>г. Москва, ул. Академика Королёва, 12</address>
								</p>
							</div>

							<span className={classes.contactDec}>
								<img src={heroImg} alt="Вступительная картинка" width={600} height={450} />
							</span>
						</div>
					</section>
				</main>
				<ContactSection></ContactSection>
			</div>
		</>
	);
};

export default contactPage;
