import React from "react";

import classes from "./contactSection.module.css";
import { Link } from "react-router-dom";

// import tg from "../../assets/images/telegram.png";
// import insta from "../../assets/images/insta.png";
// import fb from "../../assets/images/fb.png";
// import twit from "../../assets/images/twit.png";
import vk from "../../assets/images/vk.png";

const ContactSection = () => {
	return (
		<div className={`${classes.contactWrapper} contactWrapper`}>
			<div className={`${classes.contactContainer} container`}>
				<Link to="/" className="logo">
					<span>ELSYMO</span>
				</Link>
				<div className={classes.contactSocial}>
					<h3 className={classes.contactTitle}>Мы в социальных сетях:</h3>
					<div className={classes.contactSocialRow}>
						{/* <a className={classes.contactSocialLink} href="">
							<img src={tg} width={24} height={24}></img>
						</a>
						<a className={classes.contactSocialLink} href="">
							<img src={insta} width={24} height={24}></img>
						</a>
						<a className={classes.contactSocialLink} href="">
							<img src={fb} width={24} height={24}></img>
						</a>
						<a className={classes.contactSocialLink} href="">
							<img src={twit} width={24} height={24}></img>
						</a> */}
						<a className={classes.contactSocialLink} href="https://vk.com/public208925046" target="_blank">
							<img src={vk} width={24} height={24}></img>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactSection;
