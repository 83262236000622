import React from "react";
import classes from "./notFound.module.css";
import { Link } from "react-router-dom";

const NotFound = () => {
	return (
		<section className={classes.notFound}>
			<div className="container">
				<Link className="backToMain" to="/mainPage">
					⬅ Вернуться на главную
				</Link>
				<div className={classes.notFoundContent}>
					<h1 className={classes.notFoundTitle}>Страница не найдена :(</h1>
					<p className={classes.notFoundDescr}>
						Пожалуйста, воспользуйтесь навигацией или формой поиска, чтобы найти интереcующую вас информацию
					</p>
				</div>
				<span className={classes.notFoundDec}>404</span>
			</div>
		</section>
	);
};

export default NotFound;
