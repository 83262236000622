const bin = {
	binElements: [],
	addPageElements: [],
	countProduct: 0,
	countOfProducts: 0,
};

const ADD_TO_BIN = "ADD_TO_BIN";
const REMOVE_FROM_BIN = "REMOVE_FROM_BIN";
const UPDATE_BIN = "UPDATE_BIN";
const PLUS_PRICE = "PLUS_PRICE";
const MINUS_PRICE = "MINUS_PRICE";
const SET_COUNT_NULL = "SET_COUNT_NULL";
const PLUS_COUNT_OF_PRODUCT = "PLUS_COUNT_OF_PRODUCT";
const MINUS_COUNT_OF_PRODUCT = "MINUS_COUNT_OF_PRODUCT";
const SET_COUNT_NULL_OF_PRODUCT = "SET_COUNT_NULL_OF_PRODUCT";

export const BinReducer = (state = bin, action) => {
	switch (action.type) {
		case ADD_TO_BIN:
			const productIndex = state.binElements.findIndex((el) => el.id === action.payload.id);
			if (productIndex !== -1) {
				// Продукт уже есть в корзине, увеличиваем его количество
				const newBinElements = [...state.binElements];
				newBinElements[productIndex].countOfProduct += 1;
				return {
					...state,
					binElements: newBinElements,
				};
			} else {
				// Продукта еще нет в корзине, добавляем его
				return {
					...state,
					binElements: [...state.binElements, { ...action.payload, countOfProduct: 1 }],
				};
			}
		case UPDATE_BIN:
			return {
				...state,
				binElements: action.payload,
			};
		case REMOVE_FROM_BIN:
			return {
				...state,
				binElements: state.binElements.filter((product) => product.id !== action.payload),
			};

		// Остальные действия...
		default:
			return state;
	}
};

export const addToBinCreator = (payload) => {
	console.log(payload);
	return { type: "ADD_TO_BIN", payload };
};
export const updateBinCreator = (payload) => {
	return { type: "UPDATE_BIN", payload };
};
export const removeFromBinCreator = (payload) => {
	return { type: "REMOVE_FROM_BIN", payload };
};

export const plusProductCreator = (payload) => {
	return { type: "PLUS_PRICE", payload };
};

export const minusProductCreator = (payload) => {
	return { type: "MINUS_PRICE", payload };
};

export const setCountCreator = (payload) => {
	return { type: "SET_COUNT_NULL", payload };
};

export const countPageCreator = (payload) => {
	return { type: "ADD_TO_PAGE", payload };
};

export const plusCountOfProductCreator = (payload) => {
	return { type: "PLUS_COUNT_OF_PRODUCT", payload };
};

export const minusCountOfProductCreator = (payload) => {
	return { type: "MINUS_COUNT_OF_PRODUCT", payload };
};

export const setNullCountOfProductCreator = (payload) => {
	return { type: "SET_COUNT_NULL_OF_PRODUCT", payload };
};
