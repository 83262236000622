import "./App.css";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import MainPage from "./components/mainPage/mainPage";
import ContactPage from "./components/contactPage/contactPage";
import AccountPage from "./components/accountPage/accountPage";
import RegisterPage from "./components/registerPage/registerPage";
import LoginPage from "./components/loginPage/loginPage";
import ProductPage from "./components/productPage/productPage";
import AddressPage from "./components/addressPage/addressPage";
import BasketPage from "./components/basketPage/basketPage";
import NotFound from "./components/notFound/notFound";

export const App = () => {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<MainPage />} />
					<Route path="/accountPage" element={<AccountPage />} />
					<Route path="/registerPage" element={<RegisterPage />} />
					<Route path="/loginPage" element={<LoginPage />} />
					<Route path="/productPage" element={<ProductPage />} />
					<Route path="/addressPage" element={<AddressPage />} />
					<Route path="/basketPage" element={<BasketPage />} />
					<Route path="/basketPage" element={<BasketPage />} />
					<Route path="/contactPage" element={<ContactPage />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
};

export default App;
