import { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./headerPrimary.module.css";
import user from "../../assets/images/user.png";
import cart from "../../assets/images/cart.png";
import close from "../../assets/images/close.png";
import diagram from "../../assets/images/diagram.png";
import { useSelector } from "react-redux";

const HeaderPrimary = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [srmOpen, setSrmOpen] = useState(false);

	const cartItemsCount = useSelector((state) =>
		state.bin.binElements.reduce((sum, product) => sum + product.countOfProduct, 0)
	);

	return (
		<header className={classes.header}>
			<div className={`${classes.headerContainer} container`}>
				<Link to="/" className="logo">
					<span>ELSYMO</span>
				</Link>

				<nav className={classes.menu}>
					<ul className={`${classes.menuList} ${menuOpen ? classes.menuListActive : ""}`}>
						<li className={classes.menuItem}>
							<Link className={classes.menuLink} to="/productPage">
								{" "}
								Магазин{" "}
							</Link>
						</li>
						<li className={classes.menuItem}>
							<Link className={classes.menuLink} to="/contactPage">
								{" "}
								Контакты{" "}
							</Link>
						</li>
						<li className={classes.menuItem}>
							<Link className={classes.menuLink} to="/loginPage">
								{" "}
								Войти{" "}
							</Link>
						</li>
						<li className={classes.menuItem}>
							<Link className={classes.menuLink} to="/registerPage">
								{" "}
								Регистрация{" "}
							</Link>
						</li>
						<li className={classes.menuItem}>
							<Link className={classes.menuLink} to="/accountPage">
								{" "}
								Аккаунт{" "}
							</Link>
						</li>
						<li className="mobile--active">
							<ul className={`${classes.portalList}`}>
								<li className={classes.portalItem}>
									<Link className={classes.portalLink} to="/accountPage">
										{" "}
										Выйти{" "}
									</Link>
								</li>
								<li className={classes.portalItem}>
									<Link className={classes.portalLink} to="/basketPage">
										Корзина {cartItemsCount + "шт"}
									</Link>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
				<ul className={`${classes.portalList} mobile--hidden`}>
					<li
						className={`${classes.portalItem} ${srmOpen ? classes.close : ""}`}
						onClick={() => setSrmOpen(!srmOpen)}
					>
						<button className={classes.portalLink}>
							<img src={diagram} alt="Иконка диаграмы" />
							Аналитика{" "}
						</button>
					</li>
					<li className={classes.portalItem}>
						<Link className={classes.portalLink} to="/accountPage">
							<img src={user} alt="Иконка пользователя" />
							Выйти{" "}
						</Link>
					</li>
					<li className={`${classes.portalItem} portalItemCart`}>
						<Link className={classes.portalLink} to="/basketPage">
							<img src={cart} alt="Иконка корзины" /> Корзина
						</Link>
						<span className={classes.cartCount} style={{ display: cartItemsCount <= 0 ? "none" : "block" }}>
							{cartItemsCount}
						</span>
					</li>
				</ul>

				<div
					className={`${classes.burgerMenu} ${menuOpen ? classes.close : ""}`}
					onClick={() => setMenuOpen(!menuOpen)}
				>
					<span></span>
				</div>
			</div>

			<div className={`srm ${srmOpen ? "srm--active" : ""}`}>
				<ul className="srm__list">
					<button className="srm--close" onClick={() => setSrmOpen(!srmOpen)}>
						<img src={close} alt="Закрыть меню" width={16} height={16} />
					</button>
					<li className="srm__item">Аналитика</li>
					<li className="srm__item">Бухгалтерия</li>
					<li className="srm__item">Документы</li>
				</ul>
			</div>
		</header>
	);
};

export default HeaderPrimary;
