import React from "react";

import classes from "./heroSection.module.css";
import heroImg from "../../assets/images/heroImg.png";

const HeroSection = () => {
	return (
		<section className={classes.hero}>
			<div className={`${classes.heroContainer} container`}>
				<div className={classes.hero__txt}>
					<h1 className={classes.heroTitle}>Онлайн покупки!</h1>
					<span className={classes.heroDescr}>
						Самый <span>выгодный</span> интернет магазин в России с доставкой по всему миру! Покупайте{" "}
						<span>быстро</span> и <span>удобно</span> по низким ценам
					</span>
				</div>

				<span className={classes.heroDec}>
					<img src={heroImg} alt="Вступительная картинка" width={600} height={450} />
				</span>
			</div>
		</section>
	);
};

export default HeroSection;
