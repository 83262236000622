import React, { useState } from "react";
import { Link } from "react-router-dom";
import classes from "./registerPage.module.css";

const RegisterPage = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!firstName || !lastName || !username || !email || !password || !confirmPassword) {
			alert("Заполните все поля!");
			return;
		}
		if (!/\S+@\S+\.\S+/.test(email)) {
			alert("Введите правильную электронную почту!");
			return;
		}
		if (password !== confirmPassword) {
			alert("Пароли не совпадают!");
			return;
		}
		fetch("https://nmsshop.ru/api/v1/register/", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				first_name: firstName,
				last_name: lastName,
				username,
				email,
				password,
				password2: confirmPassword,
			}),
		})
			.then((response) => response.json())
			.then((data) => console.log(data))
			.catch((error) => console.error(error));
	};

	return (
		<section className={classes.register}>
			<div className={`${classes.registerContainer} container`}>
				<Link className="backToMain" to="/">
					⬅ Вернуться на главную
				</Link>
				<h1 className={classes.registerTitle}>Регистрация</h1>
				<div className={classes.regWrapper}>
					<form className={classes.regForm} onSubmit={handleSubmit}>
						<ul className="formList">
							<li className="formItem">
								<input
									placeholder="Имя"
									type="text"
									id="firstName"
									className="cardInput"
									value={firstName}
									onChange={(event) => setFirstName(event.target.value)}
								></input>
							</li>

							<li className="formItem">
								<input
									placeholder="Фамилия"
									type="text"
									id="lastName"
									className="cardInput"
									value={lastName}
									onChange={(event) => setLastName(event.target.value)}
								></input>
							</li>

							<li className="formItem">
								<input
									placeholder="Ник"
									type="text"
									id="username"
									className="cardInput"
									value={username}
									onChange={(event) => setUsername(event.target.value)}
								></input>
							</li>

							<li className="formItem">
								<input
									placeholder="Электронная почта"
									type="email"
									id="mail"
									className="cardInput"
									value={email}
									onChange={(event) => setEmail(event.target.value)}
								></input>
							</li>

							<li className="formItem">
								<input
									placeholder="Пароль"
									type="password"
									id="pass"
									className="cardInput"
									value={password}
									onChange={(event) => setPassword(event.target.value)}
								></input>
							</li>

							<li className="formItem">
								<input
									placeholder=" Проверка пароля"
									type="password"
									id="passCheck"
									className="cardInput"
									value={confirmPassword}
									onChange={(event) => setConfirmPassword(event.target.value)}
								></input>
							</li>
						</ul>

						<button type="submit" className="formBtn">
							Зарегистрироваться
						</button>
					</form>
				</div>
			</div>
		</section>
	);
};

export default RegisterPage;
