import React, { useState, useEffect } from "react";

import classes from "./addressPage.module.css";
import { Link } from "react-router-dom";

import changeToken from "../../tokenFunctions/functions";
import { Await } from "react-router-dom";
import { useSelector } from "react-redux";

const AddressPage = () => {
	const http = "https://nmsshop.ru/api/v1/";

	const [countryUser, setCountryUser] = useState("");
	const [regionUser, setRegionUser] = useState("");
	const [cityUser, setCityUser] = useState("");
	const [streetUser, setStreetUser] = useState("");
	const [phoneUser, setPhoneUser] = useState("");
	const [postalCodeUser, setPostalCodeUser] = useState("");

	const binElements = useSelector((state) => state.bin.binElements);
	console.log(binElements);

	let tokenFunc = async () => {
		let accessBearer = localStorage.getItem("access");

		let response = await fetch(`${http}user/all/`, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${accessBearer}`,
			},
		});

		let data = await response.json();

		// console.log(data)
	};

	useEffect(() => {
		tokenFunc();
	}, []);

	let orderItemCreate = async (orderId, getChoicesId, getCountOfProduct) => {
		console.log("countOfFunction");
		console.log(getChoicesId);

		let access = localStorage.getItem("access");

		let orderItem = {
			order: orderId,
			choices: getChoicesId,
			quantity: getCountOfProduct,
		};

		let response = await fetch(`${http}orderitem/create/`, {
			method: "POST",
			body: JSON.stringify(orderItem),
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${access}`,
			},
		});

		let res = await response.json();

		console.log(res);
	};

	let orderCreate = async (addressId) => {
		console.log(binElements.length);

		let order = {
			paid: false,
			adress: addressId,
		};

		let access = localStorage.getItem("access");

		let response = await fetch(`${http}order/create/`, {
			method: "POST",
			body: JSON.stringify(order),
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${access}`,
			},
		});

		let data = await response.json();

		binElements.forEach((element) => {
			orderItemCreate(data.id, element.choicesId, element.countOfProduct);
		});

		console.log(data);
	};

	let postAddress = async () => {
		let addressData = {
			country: countryUser,
			region: regionUser,
			city: cityUser,
			street: streetUser,
			phone: phoneUser,
			postal_code: postalCodeUser,
		};

		let access = localStorage.getItem("access");

		let response = await fetch(`${http}adress/create/`, {
			method: "POST",
			body: JSON.stringify(addressData), //JSON.stringify(addressData),
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${access}`,
			},
		});

		// console.log(response.ok);

		let res = await response.json();

		orderCreate(res.id);
	};

	setInterval(() => {
		changeToken();
		tokenFunc();
	}, 55000);

	function nextFunc(e) {
		e.preventDefault();
		postAddress();
	}

	function countryFunc(e) {
		setCountryUser(e.target.value);
	}
	function regionFunc(e) {
		setRegionUser(e.target.value);
	}
	function cityFunc(e) {
		setCityUser(e.target.value);
	}
	function streetFunc(e) {
		setStreetUser(e.target.value);
	}
	function phoneFunc(e) {
		setPhoneUser(e.target.value);
	}
	function postalCodeFunc(e) {
		setPostalCodeUser(e.target.value);
	}

	return (
		<section className={`${classes.addressSection}`}>
			<div className={classes.addressEntry}>
				<Link class={`${classes.addressBack} backToMain`} to="/">
					⬅ Вернуться на главную
				</Link>
				<h1 className={`${classes.addressTitle}`}>Укажите адрес доставки</h1>
			</div>
			<div className={`${classes.addressContainer} container`}>
				<div className={`${classes.addressFormWrapper}`}>
					<form className="entryForm">
						<ul className={`${classes.addressList} formList`}>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Страна </label>
								<input
									placeholder="Россия"
									type="text"
									id="country"
									value={countryUser}
									onChange={countryFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Регион </label>
								<input
									placeholder="Московская область"
									type="text"
									id="region"
									value={regionUser}
									onChange={regionFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Город </label>
								<input
									placeholder="Москва"
									type="text"
									id="city"
									value={cityUser}
									onChange={cityFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Улица </label>
								<input
									placeholder="Улица победителей"
									// required
									type="text"
									id="street"
									value={streetUser}
									onChange={streetFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Дом </label>
								<input
									placeholder="№ 9"
									// required
									type="text"
									id="buildingNumber"
									// value={buildingNumberUser}
									// onChange={buildingNumberFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Квартира </label>
								<input
									placeholder="№ 3"
									// required
									type="text"
									id="apartment"
									// value={apartmentUser}
									// onChange={apartmentFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Почтовый индекс </label>
								<input
									placeholder="100110"
									// required
									type="text"
									id="post_code"
									value={postalCodeUser}
									onChange={postalCodeFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
							<li className={`${classes.addressFormItem} formItem`}>
								<label className="cardLabel"> Номер телефона </label>
								<input
									placeholder="+7 900 555 75 75"
									// required
									type="tel"
									id="phone"
									value={phoneUser}
									onChange={phoneFunc}
									className={`${classes.addressFormInput} cardInput`}
								></input>
							</li>
						</ul>
						<button onClick={nextFunc} className={`${classes.addressSubmit} formBtn`}>
							{" "}
							Далее{" "}
						</button>
					</form>
				</div>
			</div>
		</section>
	);
};

export default AddressPage;
