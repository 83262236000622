import React from "react";

import classes from "./mainPage.module.css";

import HeaderPrimary from "../headerPrimary/headerPrimary";
import ProductMainList from "../productMainList/productMainList";
import HeroSection from "../heroSection/heroSection";
import ContactSection from "../contactSection/contactSection";

const MainPage = () => {
	return (
		<>
			<div className="wrapper">
				<HeaderPrimary></HeaderPrimary>

				<main className="main">
					<HeroSection>
						<h1 className="mainTitle">
							шоп №1
							<span className="mainSubtitle">Теперь у нас тоже есть магазин!</span>
						</h1>
					</HeroSection>
				</main>
				<ContactSection></ContactSection>
			</div>
		</>
	);
};

export default MainPage;
