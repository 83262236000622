import React, { useEffect, useState } from "react";

import classes from "./productPage.module.css";

import { Link } from "react-router-dom";

import HeaderPrimary from "../headerPrimary/headerPrimary";
import ContactSection from "../contactSection/contactSection";
import { useDispatch } from "react-redux";
import { addToBinCreator } from "../../store/binReducer"; // импортируйте действие
import { useSelector } from "react-redux";

import axios from "axios";

const ProductPage = () => {
	const [products, setProducts] = useState([]);
	useEffect(() => {
		axios
			.get("https://nmsshop.ru/api/v1/product/all/")
			.then((response) => {
				setProducts(response.data);
				console.log(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	const data = useSelector((state) => state.bin.addPageElements);
	const binElements = useSelector((state) => state.bin.binElements);
	const addPageElements = useSelector((state) => state.bin.addPageElements);

	console.log(addPageElements);

	const http = "https://nmsshop.ru/api/v1/";
	let tokenFunc = async () => {
		let accessBearer = localStorage.getItem("access");

		let response = await fetch(`${http}user/all/`, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${accessBearer}`,
			},
		});

		let data = await response.json();

		console.log(data);
	};

	useEffect(() => {
		tokenFunc();
	}, []);

	const dispatch = useDispatch();
	const handleAddToCart = (product) => {
		dispatch(addToBinCreator(product)); //
	};

	return (
		<div className="productWrapper">
			<HeaderPrimary></HeaderPrimary>
			<div className={classes.product}>
				<div className="main">
					<div className={`${classes.productContainer} container`}>
						<Link className="backToMain" to="/">
							⬅ Вернуться на главную
						</Link>
						<div className="right_bar">
							<ul className={classes.productList}>
								{products.map((product) => (
									<li className={classes.productItem} key={product.id}>
										{product.images && product.images[0] && (
											<img className={classes.productImg} src={product.images[0].image} alt={product.name} />
										)}
										<h2 className={classes.productTitle}>{product.name}</h2>
										<span className={classes.productPrice}>
											Цена:{" "}
											{product.choices && product.choices[0] ? product.choices[0].price : "Цена не указана"}{" "}
											₽
										</span>
										<button className={classes.productAddToCart} onClick={() => handleAddToCart(product)}>
											Добавить в корзину
										</button>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<ContactSection></ContactSection>
		</div>
	);
};

export default ProductPage;
