import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import classes from "./loginPage.module.css";

const LoginPage = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const http = "https://nmsshop.ru/api/v1/";
	const navigate = useNavigate();

	function entryFunc(e) {
		e.preventDefault();
		if (!validateEmail(email)) {
			setError("Неправильный формат адреса электронной почты");
			return;
		}
		const params = new FormData();
		params.set("email", email);
		params.set("password", password);

		fetch(`${http}token/`, {
			method: "POST",
			body: params,
		})
			.then((res) => {
				if (res.ok) {
					navigate("/accountPage");
					return res.json();
				} else {
					setError("Неправильный адрес электронной почты или пароль");
				}
			})
			.then((data) => {
				if (data) {
					localStorage.setItem("access", data.access);
					localStorage.setItem("refresh", data.refresh);
				}
			});
	}

	function validateEmail(email) {
		const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return re.test(email);
	}

	return (
		<section className={classes.login}>
			<div className="container">
				<Link className="backToMain" to="/">
					⬅ Вернуться на главную
				</Link>
				<h1 className={classes.loginTitle}> Вход в аккаунт </h1>
				{error && <p className={classes.loginError}>{error}</p>}
				<div className={classes.loginFormWrapper}>
					<form id="authorization">
						<ul className={`${classes.loginList} formList`}>
							<li className={`${classes.loginFormItem} formItem`}>
								<label className="cardLabel"> Почта </label>
								<input
									onChange={(e) => setEmail(e.target.value)}
									value={email}
									autoComplete="off"
									required
									type="email"
									name="username"
									placeholder="name@domain.com"
									id="email"
									className="cardInput"
								/>
							</li>
							<li className={`${classes.loginFormItem} formItem`}>
								<label className="cardLabel"> Пароль </label>
								<input
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									autoComplete="off"
									pattern="[0-9a-fA-F]{4,10}"
									title="Введите пароль состоящий минимум из 4 символов, но не более 10"
									minLength="4"
									maxLength="20"
									id="pass"
									required
									name="password"
									type="password"
									placeholder="********"
									className="cardInput"
								/>
							</li>
						</ul>
						<button onClick={entryFunc} className="formBtn" id="login-btn">
							{" "}
							Войти{" "}
						</button>
					</form>
				</div>
			</div>
		</section>
	);
};

export default LoginPage;
