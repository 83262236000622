import React, { useEffect, useState } from "react";

import classes from "./accountPage.module.css";
import { Link } from "react-router-dom";

import userProfileImg from "../../assets/images/user.png";
import changeToken from "../../tokenFunctions/functions";

const AccountPage = () => {
	const [email, setEmail] = useState("");
	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [username, setUsername] = useState("");
	const [userid, setUserId] = useState("");

	const [newEmail, setNewEmail] = useState("");
	const [newFirstname, setNewFirstname] = useState("");
	const [newLastname, setNewLastname] = useState("");
	const [newUsername, setNewUsername] = useState("");
	const [newUserid, setNewUserId] = useState("");

	const http = "https://nmsshop.ru/api/v1/";

	let tokenFunc = async () => {
		let accessBearer = localStorage.getItem("access");

		let response = await fetch(`${http}user/all/`, {
			method: "GET",
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${accessBearer}`,
			},
		});

		let data = await response.json();

		setEmail(data[0].email);
		setFirstname(data[0].first_name);
		setLastname(data[0].last_name);
		setUsername(data[0].username);
		setUserId(data[0].id);
	};

	useEffect(() => {
		tokenFunc();
	});

	setInterval(() => {
		changeToken();
		tokenFunc();
	}, 55000);

	let changeUserData = async (e) => {
		e.preventDefault();
		let accessBearer = localStorage.getItem("access");

		var userNewData = {
			email: newEmail,
			username: newUsername,
			first_name: newFirstname,
			last_name: newLastname,
		};

		let fetchResponse = await fetch(`${http}update_profile/${userid}/`, {
			method: "PUT",
			body: JSON.stringify(userNewData),
			headers: {
				"content-type": "application/json",
				authorization: `Bearer ${accessBearer}`,
			},
		});
		let res = await fetchResponse.json();
		console.log(res);

		tokenFunc();
	};

	function changeData(e) {
		changeUserData(e);
	}

	function nameFunc(e) {
		setNewFirstname(e.target.value);
	}
	function surnameFunc(e) {
		setNewLastname(e.target.value);
	}
	function nickFunc(e) {
		setNewUsername(e.target.value);
	}
	function mailFunc(e) {
		setNewEmail(e.target.value);
	}

	return (
		<section className={classes.account}>
			<div className={`${classes.accountContainer} container`}>
				<Link className="backToMain" to="/">
					⬅ Вернуться на главную
				</Link>
				<div className={classes.accountEntry}>
					<h1 className={classes.accountTitle}>Ваш аккаунт</h1>
				</div>
				<div className={classes.accountWrapper}>
					<div className={classes.accountProfile}>
						<form style={{ paddingTop: "0" }} className="entryForm">
							<ul className="formList">
								<li className={`${classes.accountFormItem} formItem`}>
									<label className="cardLabel"> Имя: Ivan </label>
									<input
										placeholder="Имя"
										type="text"
										id="name"
										value={newFirstname}
										onChange={nameFunc}
										className="cardInput"
									/>
								</li>
								<li className={`${classes.accountFormItem} formItem`}>
									<label className="cardLabel"> Фамилия: Ivanov </label>
									<input
										placeholder="Фамилия"
										type="text"
										id="surname"
										value={newLastname}
										onChange={surnameFunc}
										className="cardInput"
									/>
								</li>
								<li className={`${classes.accountFormItem} formItem`}>
									<label className="cardLabel"> Ник: Nickname </label>
									<input
										placeholder="Ник"
										type="text"
										id="nick"
										value={newUsername}
										onChange={nickFunc}
										className="cardInput"
									/>
								</li>
								<li className={`${classes.accountFormItem} formItem`}>
									<label className="cardLabel"> Электронная почта: example@email.com </label>
									<input
										placeholder="Электронная почта"
										type="email"
										id="mail"
										value={newEmail}
										onChange={mailFunc}
										className="cardInput"
									/>
								</li>
							</ul>
							<button onClick={changeData} className="formBtn" id="login-btn">
								{" "}
								Изменить данные{" "}
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AccountPage;
